<!-- CMC平台 -->
<template>
  <div>
    <div class="content">
      <div class="content_top">
        <h3>{{ text1 }}</h3>
        <!-- <p>{{ text2 }}</p> -->
        <!-- <p>{{ text3 }}</p> -->
      </div>
      <div class="content_bottom">
        <!-- <img src="https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/guanyu/logo_mean.jpg" alt="" /> -->
        <div class="video_box" v-on:click="playVideo">
          <i v-show="!isPlaying"></i>
          <video id="video" :src="videoSrc" poster="https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/guanyu/logo_mean.jpg"></video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let language = localStorage.getItem("language") || "cn";
let isPlaying = false;
import { mapState } from "vuex";

export default {
  data() {
    switch (language) {
      case "cn":
        return {
          text1: "LOGO 意涵",
          text2: "",
          text3: "",
          videoSrc: "https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/src/video/about.mp4",
          isPlaying,
        };
        break;
      case "en":
        return {
          text1: "LOGO Connotation",
          text2: "",
          text3: "",
          videoSrc: "https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/src/video/about.mp4",
          isPlaying,
        };
        break;
    }
  },
  computed: {
    ...mapState(["isLanguage"]),
  },
  methods: {
    // 播放视频
    playVideo() {
      const video = document.getElementById("video");

      if (this.isPlaying) {
        video.pause();
        this.isPlaying = false;
      } else {
        video.play();
        this.isPlaying = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  .content_top {
    width: 100%;
    max-width: 1200px;
    text-align: center;
    color: #203864;
    margin: 0 auto;
    padding-bottom: 20px;
    h3 {
      position: relative;
      font-size: 50px;
      margin-bottom: 30px;
      margin-top: 30px;
      opacity: 0.9;
      font-weight: normal;
    }
    h3::after {
      position: absolute;
      top: 120%;
      left: 48%;
      content: "";
      width: 50px;
      height: 1px;
      background-color: #ccc;
    }
    p {
      opacity: 0.9;
      font-size: 18px;
    }
  }
  .content_bottom {
    width: 100%;
    max-width: 1200px;
    margin: 20px auto 50px auto;
    .video_box {
      width: 100%;
      height: 80vh;
      position: relative;
      box-shadow: 0 15px 15px rgba(0, 0, 0, 0.05);
      i {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E %3Cpath fill='%23203864' d='M371.7 238l-176-107c-15.8-8.8-35.7 2.5-35.7 21v208c0 18.4 19.8 29.8 35.7 21l176-101c16.4-9.1 16.4-32.8 0-42zM504 256C504 119 393 8 256 8S8 119 8 256s111 248 248 248 248-111 248-248zm-448 0c0-110.5 89.5-200 200-200s200 89.5 200 200-89.5 200-200 200S56 366.5 56 256z'%3E%3C/path%3E %3C/svg%3E");
        position: absolute;
        width: 80px;
        height: 80px;
        bottom: 10%;
        left: 5%;
        opacity: 0.8;
      }
      video {
        width: 100%;
        height: 100%;
        object-fit: contain;
        cursor: pointer;
      }
    }
  }
  .content_bottom img {
    width: 100%;
  }
}

@media screen and (max-width: 750px) {
  .header {
    height: 150px;
    div {
      h3 {
        font-size: 24px;
        margin-left: 10%;
        font-weight: normal;
        padding-top: 20%;
      }
      h3::after {
        width: 30px;
      }
    }
  }
  .content {
    .wrap {
      .content_nav {
        height: 70px;
        padding-top: 2%;
        line-height: 18px;
        display: flex;
        justify-content: space-between;
        a {
          width: 120px;
          height: 70px;
          font-size: 16px;
          margin-right: 0px;
          margin-top: 10px;
        }
        .line_red::after {
          top: 44%;
          left: 2%;
          width: 117px;
        }
      }
    }

    .content_top {
      h3 {
        width: 70vw;
        font-size: 30px;
        margin: 20px auto 0 auto;
      }
      h3::after {
        top: 118%;
        left: 41%;
      }
      p {
        width: 80vw;
        margin-left: calc(50% - 40vw);
        font-size: 16px;
        line-height: 24px;
      }
    }
    .content_bottom {
      margin: 30px auto;
    }
  }
}
</style>
